import React from "react"
import Layout from "../../components/layout"
import "../services.css"
import Services from "../../components/services"
import Contact from "../../components/contact"
import SingleService from "../../components/singleService"
import websites from "../../../static/services_websites.png"
import apps from "../../../static/services_applications.png"
import digital from "../../../static/services_digital.png"
import branding from "../../../static/services_branding.png"
import Dict from "../../langs/dict"

export default () => {
  const dict = new Dict("fr")
  return (
    <Layout
      lang="fr"
      pageUrl="/services"
      seoTitle="Services: sites web, apps, stratégie digitale et design"
      seoDescription="Notre équipe d'expert maitrise parfaitement les nouvelles plateformes et technologies pour un résultat exceptionnel."
    >
      <div id="services-background">
        <Services lang="fr" />
      </div>
      <div id="single-services-background">
        <SingleService
          textPosition="left"
          image={websites}
          hl="green-hl"
          title={dict.getTrans("services1_title")}
          text={dict.getTrans("services1_text")}
          reference={dict.getTrans("services1_reference")}
          referenceProject={dict.getTrans("services1_reference_project")}
          linkto={dict.getTrans("services1_linkto")}
        />
        <SingleService
          textPosition="right"
          image={apps}
          hl="pink-hl"
          title={dict.getTrans("services2_title")}
          text={dict.getTrans("services2_text")}
          reference={dict.getTrans("services2_reference")}
          referenceProject={dict.getTrans("services2_reference_project")}
          linkto={dict.getTrans("services2_linkto")}
        />
        <SingleService
          textPosition="left"
          image={digital}
          hl="orange-hl"
          title={dict.getTrans("services3_title")}
          text={dict.getTrans("services3_text")}
          reference={dict.getTrans("services3_reference")}
          referenceProject={dict.getTrans("services3_reference_project")}
          linkto={dict.getTrans("services3_linkto")}
        />
        <SingleService
          textPosition="right"
          image={branding}
          hl="blue-hl"
          title={dict.getTrans("services4_title")}
          text={dict.getTrans("services4_text")}
          reference={dict.getTrans("services4_reference")}
          referenceProject={dict.getTrans("services4_reference_project")}
          linkto={dict.getTrans("services4_linkto")}
        />
      </div>
      <div id="contact-services-background">
        <Contact lang="fr" />
      </div>
    </Layout>
  )
}
