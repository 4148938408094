import React from "react"
import "./singleService.css"
import { Link } from "gatsby"

export default props => {
  return (
    <div
      id={
        props.textPosition == "left"
          ? "service-holder-left"
          : "service-holder-right"
      }
    >
      <div id={props.textPosition == "left" ? "left-text" : "left"}>
        {props.textPosition == "left" ? (
          <div>
            <h4 id="service-title">
              <span className={props.hl}>{props.title}</span>
            </h4>
            <p id="service-text">{props.text}</p>
            <p id="service-port-link">
              {props.reference}
              <Link to={props.linkto}>
                <span>{props.referenceProject}</span>
              </Link>
            </p>
          </div>
        ) : (
          <img src={props.image} alt="service-decoration" />
        )}
      </div>
      <div id={props.textPosition == "right" ? "right-text" : "right"}>
        {props.textPosition == "right" ? (
          <div>
            <h4 id="service-title">
              <span className={props.hl}>{props.title}</span>
            </h4>
            <p id="service-text">{props.text}</p>
            <p id="service-port-link">
              {props.reference}
              <Link to={props.linkto}>
                <span>{props.referenceProject}</span>
              </Link>
            </p>
          </div>
        ) : (
          <img src={props.image} alt="service-decoration" />
        )}
      </div>
    </div>
  )
}
